import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Global electrical design engineering consultancy
        </p>
        <p>
          me@nickturner.au
        </p>
        <p>
          Currently on design of standalone power systems under 50MW
        </p>

        <table>
        <thead>
            <tr>
                <th>SERVICES</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><span class="service-item">⦾</span> Drafting</td>
            </tr>
            <tr>
                <td><span class="service-item">⦾</span> Electrical Design</td>
            </tr>
            <tr>
                <td><span class="service-item">⦾</span> Electrical Engineering</td>
            </tr>
            <tr>
                <td><span class="service-item">⦾</span> PLC Programming (COBO, IFM, AB, ComAp)</td>
            </tr>
            <tr>
                <td><span class="service-item">⦾</span> Generator Hire</td>
            </tr>
            <tr>
                <td><span class="service-item">⦾</span> Speaker Hire</td>
            </tr>
        </tbody>
    </table>

        <p>
          <a href="https://www.engineersaustralia.org.au/sites/default/files/2022-08/code-ethics-guidelines-professional-conduct-2022.pdf">Engineers Australia</a>
        </p>
      </header>
    </div>
  );
}

export default App;
